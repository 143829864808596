import './App.css';

function App() {
    return (
        <div className="App h-full w-full">
            {/*<video src={"al_splash_av1.webm"} autoPlay={true} muted={true} loop={true} className="video -z-20"></video>*/}
            <video src={"al_splash.webm"} autoPlay={true} muted={true} loop={true} className="video -z-20"></video>

            <div className="logo z-40">
                <img src="al_logo.png" width={500} className="mx-auto mt-36"/>
            </div>

            <div
                className="bg-alaccent1 w-104 h-16 mx-auto mt-16 rounded-3xl border-alprimary border-4 hover:h-18 hover:bg-alaccent2 hover:border-b-12 transition-all duration-200">
                <a href="https://discord.gg/alternate-life" target={"_blank"}>
                    <div className="text-albgColor text-4xl mx-auto w-max mt-2 font-bold cursor-pointer bangel">
                        JOIN DISCORD
                    </div>
                </a>
            </div>


            <div className="bg-alaccent1 w-104 h-16 mx-auto mt-4 rounded-3xl border-alprimary border-4 hover:h-18 hover:bg-alaccent2 hover:border-b-12 transition-all duration-200">
                <a href="https://portal.alternate-life.de" target={"_blank"}>
                    <div className="text-albgColor text-4xl mx-auto w-max mt-2 font-bold cursor-pointer bangel">
                        PORTAL
                    </div>
                </a>
            </div>


        </div>
    );
}

export default App;
